import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import TranquilobayDifference from "../components/accommodation/how_tranquilobay_different";
import ScheduleVacation from "../components/accommodation/schedulevacation";
import Logo from "../components/common/logo";
import CompaniesGuided from "../components/common/companiesGuided";
import Instagram from "../components/common/Instagram";
import BreadCrumb from "../components/common/breadcrumb";
import PanamaResort from "../components/accommodation/panamaresort";
import VacationExperience from "../components/vacation_packages/experience";
import SliderAndContent from "../components/common/SliderAndContent";
import Seo from "../components/seo";
import useIsVisible from "../hooks/isVisible";

const Vacationpackages = ({
  data: { sanityVacationpackagesPage, sanitySitesettings },
}) => {
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);

  let seo;
  let hero;
  let breadcrumb;
  let guide;
  let panamaVacation;
  let yourFvt;
  let tranqiulobay;
  let activiy;

  sanityVacationpackagesPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "panamaVacation") {
      panamaVacation = element;
    } else if (element._type === "yourFvt") {
      yourFvt = element;
    } else if (element._type === "tranqiulobay") {
      tranqiulobay = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "activiy") {
      activiy = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h1Size="text-[44px] leading-[70px] tracking-[2px]"
        h2Size="text-3xl sm:text-[50px] leading-[40px] sm:leading-[60px]"
      />
      <Logo logo={sanitySitesettings?.logo} />
      {sanityVacationpackagesPage?.contentslider?.map((item, index) => (
        <SliderAndContent
          data={item}
          key={index}
          sliderId={`vacation-${index}`}
        />
      ))}
      <div ref={elemRef}>
        {isVisible && (
          <>
            {activiy && <VacationExperience data={activiy} show="show-up" />}
            {panamaVacation && (
              <ScheduleVacation data={panamaVacation} show="show-up" />
            )}

            {yourFvt && (
              <PanamaResort
                data={yourFvt}
                color="text-white"
                minH="min-h-[80vh] sm:min-h-[120vh]"
                bgUrl={`linear-gradient(to bottom,rgba(146, 146, 146, 0.90),rgba(192, 192, 192, 0.90)),url(${yourFvt.sectionBG.asset.url})`}
                bgSize="bg-cover bg-repeat-x"
                show="show-up"
              />
            )}
            {tranqiulobay && (
              <TranquilobayDifference data={tranqiulobay} show="show-up" />
            )}
            {breadcrumb && <BreadCrumb path={breadcrumb} show="show-up" />}
            <Instagram />
            {guide && (
              <>
                <CompaniesGuided data={guide} show="show-up" />
                <Logo logo={sanitySitesettings?.logo} />
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Vacationpackages;

export const query = graphql`
  query {
    sanityVacationpackagesPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityActiviy {
          _type
          heading
          subheading
          sectionBG {
            asset {
              url
            }
          }
          activityItem {
            _rawContent
            heading
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }

        ... on SanityPanamaVacation {
          _type
          desLink
          description
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          scheduleItem {
            heading
            number
            subHeading
            svg
            itemImg {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityYourFvt {
          _type
          sectionBG {
            asset {
              url
            }
          }
          heading
          txt
          youtubeChannelId
          coverImg {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
          vidoeItems {
            title
            videoId
          }
        }
        ... on SanityTranqiulobay {
          _type
          _rawContent
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderItems {
            title
            content
            ctaButton1 {
              link
              title
              type
              variant
              formId
            }
            slideBg {
              asset {
                url
              }
            }
          }
          title
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
      }
      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
